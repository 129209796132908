import { Contact } from "@components/home/sections/contact"
import { GlobalStyle } from "@components/layout/Global"
import { MaxWidth } from "@components/layout/max-width"
import { Meta } from "@components/meta"
import SEO from "@components/seo"
import css from "@emotion/css"
import React from "react"
import { Helmet } from "react-helmet"

const NotFoundPage = () => (
  <>
    <GlobalStyle />
    <Meta />
    <Helmet>
      <link
        href="https://fonts.googleapis.com/css?family=Montserrat:400,800|Nunito+Sans:800"
        rel="stylesheet"
      />
    </Helmet>
    <SEO title="404: Not found" />
    <MaxWidth
      css={css`
        color: white;
      `}
    >
      <h1>NOT FOUND</h1>
      <p>You just hit a route that doesn&#39;t exist.</p>
      <p>
        <a
          href="/"
          css={css`
            color: white;
          `}
        >
          Go home
        </a>
      </p>
    </MaxWidth>
    <Contact />
  </>
)

export default NotFoundPage
